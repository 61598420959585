<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-card style="width: 100%;">
      <v-card-text>
       <!-- IOR Vatican Euro -->
            <v-row no-gutters class="mb-5"
              ><v-col cols="12" class="font-weight-bold subtitle-1" >{{ $t("donation.bankTransfer.IORVaticanTitle") }} <span class="text-decoration-underline">{{ $t("donation.bankTransfer.IORVaticanEurosTitle") }}</span></v-col>
              <v-col :cols="columnLabelCols">{{ $t("donation.bankTransfer.IBAN") }} </v-col
              >
              <v-col :cols="columnValueCols" class="font-weight-bold">VA18001000000020842006</v-col
              >
              <v-col :cols="columnLabelCols">{{ $t("donation.bankTransfer.SwiftCode") }}</v-col>
              <v-col :cols="columnValueCols" class="font-weight-bold">IOPRVAVX</v-col>
            </v-row>
            <!-- IOR Vatican USD -->
            <v-row no-gutters class="mb-5"
              ><v-col cols="12" class="font-weight-bold subtitle-1" >{{ $t("donation.bankTransfer.IORVaticanTitle") }} <span class="text-decoration-underline">{{ $t("donation.bankTransfer.IORVaticanUSDTitle") }}</span></v-col>
              <v-col :cols="columnLabelCols">{{ $t("donation.bankTransfer.IBAN") }} </v-col
              >
              <v-col :cols="columnValueCols" class="font-weight-bold">VA07001000000020842010</v-col
              >
              <v-col :cols="columnLabelCols">{{ $t("donation.bankTransfer.SwiftCode") }}</v-col>
              <v-col :cols="columnValueCols" class="font-weight-bold">IOPRVAVX</v-col>
            </v-row>
            <!-- Signis Foundation at BNP Paribas Fortis Bank -->
            <v-row no-gutters 
              ><v-col cols="12" class="font-weight-bold subtitle-1" >{{ $t("donation.bankTransfer.SignisFoundationTitle") }} </v-col>
              <v-col cols="12" >{{ $t("donation.bankTransfer.BNPParibasFortisBankTitle") }} </v-col>
              <v-col :cols="columnLabelCols">{{ $t("donation.bankTransfer.IBAN") }} </v-col
              >
              <v-col :cols="columnValueCols" class="font-weight-bold">BE87 0014 8199 4894</v-col
              >
              <v-col :cols="columnLabelCols">{{ $t("donation.bankTransfer.SwiftCode") }}</v-col>
              <v-col :cols="columnValueCols" class="font-weight-bold">GEBABEBB</v-col>
            </v-row>
        
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({}),
    computed: {
    ...mapGetters(["user", "language"]),
    columnLabelCols(){
      return this.language == "en" || this.language == "fr"? 2 : 3
    },
    columnValueCols(){
       return this.language == "en" || this.language == "fr"? 10 : 9
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
