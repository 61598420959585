<template>
  <v-container class="content-container pa-0 ma-0">
    <v-row>
      <v-col cols="12" md="12" class="pt-0">
        <v-tabs v-model="selectedTab">
          <v-tab>{{ $t("donation.tab.creditCard") }}</v-tab>
          <v-tab>{{ $t("donation.tab.bankTransfer") }}</v-tab>
          <v-tab>{{ $t("donation.tab.cheque") }}</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <credit-card ref="creditCard" v-show="selectedTab === 0"></credit-card>
        <bank-transfer v-show="selectedTab === 1"></bank-transfer>
        <cheque v-show="selectedTab === 2"></cheque>
      </v-col>
    </v-row>
    <spinner ref="spinner"></spinner>
  </v-container>
</template>

<script>
import Spinner from "@/components/Layout/Spinner.vue";
import CreditCard from "@/components/Donation/CreditCard.vue";
import BankTransfer from "@/components/Donation/BankTransfer.vue";
import Cheque from "@/components/Donation/Cheque.vue";

export default {
  components: {
    Spinner,
    CreditCard,
    BankTransfer,
    Cheque,
  },
  beforeRouteEnter(to, from, next) {
    if (
      getParameterByName("orderID") != null &&
      getParameterByName("SHASIGN") != null &&
      getParameterByName("amount") != null &&
      getParameterByName("STATUS") != null
    ) {
      let protocol = window.top.location.protocol;
      let host = window.top.location.host;
      let pathname = window.top.location.pathname;

      window.top.location.href = `${protocol}//${host}/#/${pathname}/?orderID=${getParameterByName(
        "orderID"
      )}&currency=${getParameterByName("currency")}&amount=${getParameterByName(
        "amount"
      )}&PM=${getParameterByName("PM")}&ACCEPTANCE=${getParameterByName(
        "ACCEPTANCE"
      )}&STATUS=${getParameterByName("STATUS")}&CARDNO=${getParameterByName(
        "CARDNO"
      )}&ED=${getParameterByName("ED")}&CN=${getParameterByName("CN")}&TRXDATE=${getParameterByName(
        "TRXDATE"
      )}&PAYID=${getParameterByName("PAYID")}&NCERROR=${getParameterByName(
        "NCERROR"
      )}&BRAND=${getParameterByName("BRAND")}&IP=${getParameterByName(
        "IP"
      )}&SHASIGN=${getParameterByName("SHASIGN")}`;
    }

    function getParameterByName(name, url = window.location.href) {
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    next();
  },
  //before route update check query string to see if there is returnmac and hosted checkout id
  beforeRouteUpdate(to, from, next) {
    const orderId = to.query.orderID;
    const status = to.query.STATUS;
    const shaSign = to.query.SHASIGN;
    const amount = to.query.amount;
    if (
      (from.path.includes("profile") || from.path.includes("donation")) &&
      orderId != null &&
      status != null &&
      shaSign != null &&
      amount != null
    ) {
      if (this.selectedTab === 0) {
        this.$refs.creditCard.getCheckoutStatus(to.query);
      }
      // else if (this.selectedTab === 2) {
      // this.$refs.eventRegistration.getCheckoutStatus(to.query);
      // }
    }
    next();
  },
  data: () => ({
    selectedTab: 0,
    isEditing: false,
  }),
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.$nextTick();
      this.$refs.creditCard.initialize();
      // this.$refs.eventRegistration.initialize();

      // this.$refs.spinner.on();
      // await this.$refs.profileForm.initialize();
      // this.$refs.spinner.off();
    },
    // enableEdit() {
    //   this.isEditing = true;
    //   if (this.selectedTab === 0) {
    //     this.$refs.profileForm.enableEdit();
    //   }
    // },
    // cancelEdit() {
    //   this.isEditing = false;
    //   if (this.selectedTab === 0) {
    //     this.$refs.profileForm.cancelEdit();
    //   }
    // },
    // saveEdit() {
    //   if (this.selectedTab === 0) {
    //     this.$refs.profileForm.saveEdit();
    //   }
    // },
    // async setIsEditing(val) {
    //   if ((val != null) & (typeof val != "undefined")) {
    //     this.isEditing = val;
    //   }
    // },
    openDonation() {
      this.selectedTab = 0;
    },
  },
};
</script>
