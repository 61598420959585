import { app } from "@/main.js";
export const validationRules = {
  data() {
    return {
      validationRulesPassword: [
        (v) => !!v || app.$i18n.t("validationRules.password.required"),
        (v) => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
          return (
            pattern.test(v) ||
            // "Min. 8 characters with at least one capital letter, a number and a special character."
            app.$i18n.t("validationRules.password.validation")
          );
        },
      ],
      validationRulesEmail: [
        (v) => {
          if (v !== "") {
            return /.+@.+\..+/.test(v) || app.$i18n.t("validationRules.email.validation");
          }
          return false;
        },
      ],
      validationRulesNotEmpty: [(v) => !!v || app.$i18n.t("validationRules.required")],
    };
  },
};
