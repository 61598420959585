<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-card style="width: 100%">
      <v-card-text>
            <v-row no-gutters 
              ><v-col cols="12" class="font-weight-bold subtitle-1" >{{ $t("donation.cheque.title") }} </v-col>
              <v-col cols="12" v-html="$t('donation.cheque.address')"></v-col>
            </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
</style>
