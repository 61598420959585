<template>
  <v-container class="ma-0 pa-0" fluid>
    <payment-receipt
      :createdPaymentOutput="createdPaymentOutput"
      :type="'membership'"
      ref="paymentReceipt"
      @receipt-closed="isTableVisible = true"
    ></payment-receipt>
    <payments ref="payments" @payment-closed="paymentClosed" :type="'donation'"></payments>
    <v-progress-circular
      v-show="isReceiptLoading"
      class="loading-indicator"
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-container>
</template>

<script>
import Payments from "@/components/Payments/Payments";
import PaymentReceipt from "@/components/Payments/PaymentReceipt";
import MembershipApi from "@/api/MembershipApi";
import PaymentApi from "@/api/PaymentApi";
import Bus from "@/bus";
import { mapGetters } from "vuex";

export default {
  components: { Payments, PaymentReceipt },
  data: () => ({
    //before route enter return from Hosted Checkout in iFrame set main window to query string

    itemsTableFooterProps: {
      "items-per-page-options": [10, 20, 50, -1],
    },
    expanded: [],
    loading: false,
    isTableVisible: true,
    membershipApi: new MembershipApi(),
    paymentApi: new PaymentApi(),
    memberships: [],
    createdPaymentOutput: {},
    isReceiptLoading: false,
  }),
  computed: {
    ...mapGetters(["user"])
  },
  methods: {
    async initialize() {
      this.openDonationCheckoutDetails()
      // this.fetchCampaignsData();
    },
    // async fetchCampaignsData() {
    //   this.loading = true;
    //   const result = await this.membershipApi.getList(this.user.id);
    //   this.loading = false;

    //   if (result.error) {
    //     Bus.$emit("alert", "error", result.error);
    //     return;
    //   }

    //   this.memberships = result;
    // },
    async fetchPaymentReceipt(paymentId) {
      this.isTableVisible = false;
      this.isReceiptLoading = true;
      var receiptData = { userId: this.user.id, paymentId: paymentId };
      const result = await this.paymentApi.getReceiptById(receiptData);

      if (result.error) {
        Bus.$emit("alert", "error", result.error);
        this.isReceiptLoading = false;
        this.isTableVisible = true;
        return;
      }

      this.createdPaymentOutput = result.paymentReceipt;
      this.isReceiptLoading = false;

      this.$refs.paymentReceipt.open();
    },
    getCheckoutStatus(hostedCheckoutData) {
      this.$refs.payments.getCheckoutStatus(hostedCheckoutData);
      this.$refs.payments.isShowDonationCheckoutDetails = false;
      this.$refs.payments.isShowHostedCheckoutIFrame = false;
      this.deleteQueryString();
    },
    deleteQueryString() {
      let query = {};
      this.$router.replace({ query });
    },
    openDonationCheckoutDetails() {
      // this.isTableVisible = false;
      this.$refs.payments.openDonationCheckoutDetails();
    },
    paymentClosed() {
      this.openDonationCheckoutDetails() 
      // this.isTableVisible = true;
      // this.fetchCampaignsData();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-table {
  line-height: 4.5rem;
}
::v-deep .v-data-table-header th {
  font-size: 0.875rem !important;
  letter-spacing: 0.078rem;
  font-weight: 500;
}
::v-deep .v-data-table__wrapper tbody {
  td {
    font-size: 1rem !important;
  }
}
.loading-indicator {
  $size: 70px;
  position: absolute;
  top: calc(50% - #{$size} / 2);
  left: calc(50% - #{$size} / 2);
}
</style>
